<template>
  <div :class="$style.wrapper">
    <div>
      <idc-logo width="100%" height="48px" color="white" />
    </div>
    <div>
      <div :class="$style.preinscription">
        <a-button
          mode="primary"
          v-if="period.enabled_preregisters"
          @click="$router.push({ name: 'guest_preinscription' })"
        >
          Crear cuenta
        </a-button>
      </div>

      <form
        class="space-y-4"
        @submit.prevent="
          login(guest).then(() => $router.push({ name: 'home' }))
        "
        style="max-width: 315px"
        @keydown="removeError($event.target.id)"
      >
        <p>
          {{ lang.login.welcome_message }}
        </p>

        <a-input
          id="email"
          placeholder="example@email.com"
          :error="error('email')"
          :label="lang.common.email"
          v-model="guest.email"
        />

        <a-input
          id="password"
          placeholder="***********"
          :error="error('password')"
          :label="lang.common.password"
          v-model="guest.password"
          type="password"
        />

        <div class="flex justify-between my-4">
          <a-checkbox v-model="guest.remember">
            {{ lang.login.remember }}
          </a-checkbox>
          <router-link :to="{ name: 'recover-account' }" class="text-primary">
            {{ lang.login.forgot_my_password }}
          </router-link>
        </div>

        <a-button
          mode="primary"
          :loading="loading"
          :disabled="loading"
          type="submit"
          class="w-full"
        >
          {{ lang.login.signin }}
        </a-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IdcLogo from "@/components/ui/IDCLogo.vue";
export default {
  data: () => ({
    guest: {
      email: "",
      password: "",
      remember: false,
    },
    period: {
      settings: {
        schedule_shifts: []
      }
    }
  }),
  computed: {
    ...mapGetters({
      error: "error",
      lang: "lang",
      isAuthenticated: "session/isAuthenticated",
    }),
    ...mapState({
      loading: (state) => state.session.loading,
    }),
  },
  methods: mapActions({
    login: "session/login",
    translate: "translate",
    removeError: "removeError",
  }),
  components: {
    IdcLogo,
  },
  created() {
      this.$repository.preinscriptions
        .enabledPreinscriptionsPeriod()
        .then(({ data }) => {
          this.period = {
            ...data,
            settings: {
              ...data.settings,
              schedule_shifts: data.settings.schedule_shifts.map($0 => ({
                label: this.lang.common.schedule_shifts[$0],
                value: $0
              }))
            }
          }
        })
    }
};
</script>

<style module>
.preinscription {
  @apply absolute top-1 right-0 m-4;
}
.wrapper {
  @apply grid md:grid-cols-2 h-screen grid-cols-1;
}

.wrapper > div:first-child {
  @apply bg-gray-400 hidden md:flex justify-center items-center;
}

.wrapper > div:nth-child(2) {
  @apply flex justify-center items-center;
}

.wrapper > div:nth-child(2) > form > p {
  @apply text-center text-xl pb-8;
}
</style>
